$(document).ready(initPage);

function initPage() {
  /** Topmenu **/
  if (window.matchMedia('(max-width: 1024px)').matches) {
    $('.header-menu li.dropdown').click(function () {
      $(this).toggleClass('active-m');
    });
  } else {
    $('.header-b-sticky .header-menu li.dropdown').hover(
      function () {
        let timeout = $(this).data('timeout');
        if (timeout) clearTimeout(timeout);
        $(this).addClass('active');
      },
      function () {
        $(this).data(
          'timeout',
          setTimeout(
            $.proxy(function () {
              $(this).removeClass('active');
            }, this),
            500
          )
        );
      }
    );
  }

  $('.header-nav').click(function () {
    $(this).toggleClass('active');
    $('.header-b-sticky').toggleClass('active');
  });

  /* Scroll */
  checkSticky();
  $(window).scroll(function () {
    checkSticky();
  });

  function checkSticky() {
    const scrollPos = $(document).scrollTop();
    if (scrollPos) {
      $('body').addClass('sticky');
    } else {
      $('body').removeClass('sticky');
      $('.header-nav').removeClass('active');
      $('.header-b-sticky').removeClass('active');
    }
  }

  $('.jarallax').jarallax({
    speed: 0.5,
  });

  // Home shop slider
  const swiper1 = new Swiper('.swiper-home-shop-1', {
    loop: true,
    navigation: {
      nextEl: '.section-home-shop-1 .swiper-next',
      prevEl: '.section-home-shop-1 .swiper-prev',
    },
    slidesPerView: 4,
    spaceBetween: 28,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      400: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      576: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
    },
  });

  // Home services slider
  const swiper2 = new Swiper('.swiper-home-services', {
    // loop: true,
    navigation: {
      nextEl: '.section-home-services .swiper-next',
      prevEl: '.section-home-services .swiper-prev',
    },
    slidesPerView: 3,
    spaceBetween: 0,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
    },
  });

  // Home shop slider 2
  const swiper3 = new Swiper('.swiper-home-shop-2', {
    loop: true,
    navigation: {
      nextEl: '.section-home-shop-2 .swiper-next',
      prevEl: '.section-home-shop-2 .swiper-prev',
    },
    slidesPerView: 4,
    spaceBetween: 28,
    breakpoints: {
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      400: {
        slidesPerView: 2,
        spaceBetween: 15,
      },
      576: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 28,
      },
    },
  });

  // Membership services
  // Home services slider
  const swiperMembership = new Swiper('.swiper-membership-services', {
    // loop: true,
    navigation: {
      nextEl: '.membership-services-list .swiper-next',
      prevEl: '.membership-services-list .swiper-prev',
    },
    slidesPerView: 3,
    spaceBetween: 0,
    breakpoints: {
      320: {
        slidesPerView: 1,
      },
      480: {
        slidesPerView: 2,
      },
      640: {
        slidesPerView: 3,
      },
    },
  });

  const swiperSustain = new Swiper('.swiper-sustain-projects', {
    // loop: true,
    navigation: {
      nextEl: '.swiper-sustain-projects .sp-nav .swiper-next',
      prevEl: '.swiper-sustain-projects .sp-nav .swiper-prev',
    },
    slidesPerView: 1,
    spaceBetween: 0,
    effect: 'fade',
    simulateTouch: false,
    loop: true,
    autoHeight: true,
  });

  $('.sp').each(function () {
    const gal = $(this).find('.swiper-sustain-projects-gallery')[0];
    const galNavPrev = $(this).find(
      '.swiper-sustain-projects-gallery .swiper-prev'
    )[0];
    console.log(galNavPrev);
    const galNavNext = $(this).find(
      '.swiper-sustain-projects-gallery .swiper-next'
    )[0];

    new Swiper(gal, {
      // loop: true,
      navigation: {
        nextEl: galNavNext,
        prevEl: galNavPrev,
      },
      slidesPerView: 1,
      spaceBetween: 0,
      loop: true,
    });
  });

  // sustainability
  $('.sustain-intro-toggler').click(function () {
    $('.section-sustain-intro').toggleClass('expanded');
  });

  // Product
  const swiperProductThumbs = new Swiper('.swiper-product-thumbs', {
    slidesPerView: 'auto',
    spaceBetween: 0,
    allowTouchMove: false,
    breakpoints: {
      320: {
        slidesPerView: 4,
      },
      480: {
        slidesPerView: 4,
      },
      992: {
        slidesPerView: 4,
      },
      1600: {
        slidesPerView: 'auto',
      },
    },
  });

  const swiperProductGallery = new Swiper('.swiper-product-gallery', {
    loop: true,
    effect: 'fade',
    navigation: {
      nextEl: '.membership-services-list .swiper-next',
      prevEl: '.membership-services-list .swiper-prev',
    },
    slidesPerView: 1,
    spaceBetween: 0,
    thumbs: {
      swiper: swiperProductThumbs,
    },
  });

  $('.section-product #qtyDec').click(function () {
    const numInput = $('.qty-num .num')[0];
    let qtyCurr = $(numInput).val();
    if (qtyCurr > 1) {
      $('.qty-num .num').val(--qtyCurr);
    }
  });
  $('.section-product #qtyInc').click(function () {
    const numInput = $('.qty-num .num')[0];
    let qtyCurr = $(numInput).val();
    $('.qty-num .num').val(++qtyCurr);
  });

  $('.product-details-h').click(function () {
    $(this).parent().toggleClass('active');
  });

  $('.product-more-h').click(function () {
    $(this).parent().toggleClass('active');
  });
}
